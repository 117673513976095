$(document).ready(function () {
    $("#email").on("input", function () {
        $("#errorEmail").remove();
        $("#errorPassword").remove();
    });

    $("#password_login").on("input", function () {
        $("#errorEmail").remove();
        $("#errorPassword").remove();
    });

    $('#formLogin').submit(function () {
        $('#btnLogin').prop('disabled', true);
    });
});
